import fastDeepEqual from 'fast-deep-equal'
import { RequestArgs } from 'ermes-ts-sdk/dist/base'
import { useState, useEffect, useRef, useContext, useMemo } from 'react'
import { Configuration as PublicAPIConfiguration } from 'ermes-ts-sdk'
import { Configuration as BackofficeAPIConfiguration } from 'ermes-backoffice-ts-sdk'
import { useToken } from '../state/auth/auth.hooks'
import { AppConfig, AppConfigContext } from '../config'
import useAxios from 'axios-hooks'

/**
 * Handle Deep Equals
 * @see https://github.com/facebook/react/issues/16221#issuecomment-515489115
 * @param value
 * @param customEquals
 */
export function useOriginalCopy<T>(
  value: T,
  customEquals: (x: T, y: T) => boolean = fastDeepEqual
): T {
  const copy = useRef(value)

  if (!customEquals(copy.current, value)) {
    copy.current = value
  }

  return copy.current
}

export type APIType = 'public' | 'backoffice'

/**
 * Select a configutation type, either public or backoffice
 * @param type
 */
export function useAPIConfiguration(type: APIType = 'public') {
  const appConfig = useContext<AppConfig>(AppConfigContext)
  const token = useToken()
  const apiConfig = useMemo<PublicAPIConfiguration | BackofficeAPIConfiguration>(
    () => {
      const APIConfig = type === 'public' ? PublicAPIConfiguration : BackofficeAPIConfiguration
      return new APIConfig({
        apiKey: token || '',
        basePath: appConfig.backend?.url
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  ) // eslint-enable-next-line react-hooks/exhaustive-deps

  return { apiConfig, token }
}

// Any generated function that return an Axios configuration
// needed by Axios hooks
type AxiosParamFunction = (...args: any[]) => Promise<RequestArgs>

// The AxiosParamCreator return an object where each key is
// an async function returning a configuration
type ApiAxiosParam = {
  [k: string]: AxiosParamFunction
}

type ApiAxiosParamCreator = (
  cfg?: PublicAPIConfiguration | BackofficeAPIConfiguration
) => ApiAxiosParam

// Async fn to extract params in the useEffect hook
async function extractRequestArgsParams(
  paramsPromise: Promise<RequestArgs>,
  done: (arg: RequestArgs | null) => void
) {
  const res = await paramsPromise
  done(res)
}

/**
 * XApiAxiosParamCreator return a set of function returning a promise
 * which has the RequestArgs used by useAxios hooks
 * @param fn
 * @param args
 * @param customEquals
 */
export function useAPIRequestArgs<F extends AxiosParamFunction>(
  fn: F,
  args?: Parameters<F>,
  customEquals: (x?: Parameters<F>, y?: Parameters<F>) => boolean = fastDeepEqual
) {
  const previousRef = useRef<Parameters<F> | undefined>(undefined)
  const [requestArgs, setRequestArgs] = useState<RequestArgs | null>(null)
  useEffect(
    () => {
      if (
        !customEquals(previousRef.current, args) ||
        (typeof args === 'undefined' && requestArgs === null)
      ) {
        previousRef.current = args
        const funcResult = Array.isArray(args) ? fn.apply(null, args) : fn.call(null)
        extractRequestArgsParams(funcResult, setRequestArgs)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [args]
  )

  return requestArgs
}

/**
 * Options for getting request args
 */
export interface APIAxiosHookOpts<T extends ApiAxiosParamCreator> {
  paramCreator: T // param creator
  methodName: keyof ReturnType<T> // method of paramCreator, API to be called
  type: APIType // API Type (public or backoffice)
  args?: Parameters<AxiosParamFunction> // paramether for the method
}

/**
 *
 * @param opts
 */
export function useAPIAxiosRequestArgs<T extends ApiAxiosParamCreator>(
  opts: APIAxiosHookOpts<T>
): RequestArgs {
  const { paramCreator, methodName, type, args } = opts
  // Get configuration (public or backoffice)
  const { apiConfig } = useAPIConfiguration(type)
  // Get generated parameters from selected creator: object where each key is a method
  // returning and axios configuration
  const params: ApiAxiosParam = useMemo(
    () => paramCreator(apiConfig),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiConfig]
  )
  // Get desired Method from parameters
  const method = params[methodName as string]
  // Extract the arguments - it's an "async" hook
  const requestArgs = useAPIRequestArgs(method, args)
  return requestArgs || { url: '', options: {} }
}

/**
 * Configures useAxios to work with ParamCreator generated by Openapi generator
 * Which returns the axios configuration asynchronously
 * @param opts - options for getting the arguments
 * @param manual - manual or as soon as the component is mounted
 */
export function useAxiosWithParamCreator<T extends ApiAxiosParamCreator, R>(
  opts: APIAxiosHookOpts<T>,
  manual: boolean = true
) {
  const { options, url } = useAPIAxiosRequestArgs<T>(opts)
  return useAxios<R>(
    {
      ...options,
      url
    },
    { manual }
  )
}
